<template>
    <!-- ======= About Section ======= -->
    <section id="about" class="about">
      <div class="container" data-aos="fade-up">

        <div class="section-title">
          <h2>Perfil</h2>
          <p>Trabalho com desenvolvimento de sistemas há mais de 10 anos.</p>
          <p>Possuo habilidades sólidas em PHP, Laravel, Codeigniter, Vue.js , Mysql, Postgre, SQLServer, C++ , Plataforma Arduino, Genexus e Robotica.</p>
          <p>Dedicação e comprometimento são características marcantes da minha trajetória, que inclui atuação em empresas de destaque no mercado.</p>
          <p>Busco sempre aprimorar meus conhecimentos e habilidades, e estou motivado para contribuir com o sucesso da empresa. </p>
          <p>Acredito que posso agregar valor à equipe e contribuir para o crescimento da empresa.</p>
        </div>

        <div class="row ">
          <div class="col-lg-4 text-center">
            <img src="assets/img/profile-img.jpg" class="img-fluid" alt="Imagem do perfil de Fabio Andolphi">
          </div>
          <div class="col-lg-8 pt-4 pt-lg-0 content">
            <h3>Desenvolvedor Full Stack | PHP | Laravel</h3>
            
            <div class="row">
              <div class="col-lg-6">
                <ul>
                  <li><i class="bi bi-chevron-right"></i> <strong>Whatsapp:</strong> <span>{{ whatsapp }}</span></li>
                  <li><i class="bi bi-chevron-right"></i> <strong>E-mail:</strong> <span>{{ email }}</span></li>
                  <li><i class="bi bi-chevron-right"></i> <strong>Cidade:</strong> <span>Itaperuna - RJ </span></li>
                </ul>
              </div>
              <div class="col-lg-6">
                <ul>
                  <li><i class="bi bi-chevron-right"></i> <strong>Nível:</strong> <span>Pleno/Senior</span></li>
                </ul>
              </div>
            </div>
            
          </div>
        </div>

      </div>
    </section><!-- End About Section -->
</template>

<script>
import { mapState } from 'vuex'
export default{
    name: 'AboutVue',
    computed: {
      ...mapState({
        whatsapp: state=> state.whatsapp,
        email: state=> state.email,
      })
    }
}
</script>

<style scoped>
  img{
    width: 192px;
    height: 192px;
  }
</style>