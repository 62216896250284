<template>
    <!-- ======= Skills Section ======= -->
    <section id="skills" class="skills section-bg">
      <div class="container" data-aos="fade-up">

        <div class="section-title">
          <h2>Skills</h2>
          <!-- <p>Trabalho com desenvolvimento de sistemas há mais de 10 anos.</p>
          <p>Possuo habilidades sólidas em PHP, Laravel, Codeigniter, Vue.js , Mysql, Postgre, SQLServer, C++ , Plataforma Arduino, Genexus e Robotica.</p>
          <p>Dedicação e comprometimento são características marcantes da minha trajetória, que inclui atuação em empresas de destaque no mercado.</p>
          <p>Busco sempre aprimorar meus conhecimentos e habilidades, e estou motivado para contribuir com o sucesso da empresa. Acredito que posso agregar valor à equipe e contribuir para o crescimento da empresa.</p> -->
        </div>

        <div class="row skills-content">

          <div class="col-lg-6">
            <div class="progress">
              <span class="skill">PHP <i class="val">+10 Anos</i></span>
              <div class="progress-bar-wrap">
                <div class="progress-bar" role="progressbar" aria-valuenow="10" aria-valuemin="0" aria-valuemax="10" aria-label="php"></div>
              </div>
            </div>
            
            <div class="progress">
              <span class="skill">Laravel <i class="val">5 Anos</i></span>
              <div class="progress-bar-wrap">
                <div class="progress-bar" role="progressbar" aria-valuenow="5" aria-valuemin="0" aria-valuemax="10" aria-label="laravel"></div>
              </div>
            </div>
            <div class="progress">
              <span class="skill">Codeigniter <i class="val">3 Anos</i></span>
              <div class="progress-bar-wrap">
                <div class="progress-bar" role="progressbar" aria-valuenow="3" aria-valuemin="0" aria-valuemax="10" aria-label="Codeigniter"></div>
              </div>
            </div>
            <div class="progress">
              <span class="skill">SQL <i class="val">+10 Anos</i></span>
              <div class="progress-bar-wrap">
                <div class="progress-bar" role="progressbar" aria-valuenow="10" aria-valuemin="0" aria-valuemax="10" aria-label="SQL"></div>
              </div>
            </div>
            <div class="progress">
              <span class="skill">Mysql <i class="val">+10 Anos</i></span>
              <div class="progress-bar-wrap">
                <div class="progress-bar" role="progressbar" aria-valuenow="10" aria-valuemin="0" aria-valuemax="10" aria-label="Mysql"></div>
              </div>
            </div>
            <div class="progress">
              <span class="skill">MariaDB <i class="val">+10 Anos</i></span>
              <div class="progress-bar-wrap">
                <div class="progress-bar" role="progressbar" aria-valuenow="10" aria-valuemin="0" aria-valuemax="10" aria-label="MariaDB"></div>
              </div>
            </div>
            <div class="progress">
              <span class="skill">PostgreSQL <i class="val">+10 Anos</i></span>
              <div class="progress-bar-wrap">
                <div class="progress-bar" role="progressbar" aria-valuenow="10" aria-valuemin="0" aria-valuemax="10" aria-label="PostgreSQL"></div>
              </div>
            </div>
            <div class="progress">
              <span class="skill">MSSQL <i class="val">+10 Anos</i></span>
              <div class="progress-bar-wrap">
                <div class="progress-bar" role="progressbar" aria-valuenow="10" aria-valuemin="0" aria-valuemax="10" aria-label="MSSQL"></div>
              </div>
            </div>
            <div class="progress">
              <span class="skill">Bootstrap <i class="val">4 Anos</i></span>
              <div class="progress-bar-wrap">
                <div class="progress-bar" role="progressbar" aria-valuenow="4" aria-valuemin="0" aria-valuemax="10" aria-label="Bootstrap"></div>
              </div>
            </div>
            <div class="progress">
              <span class="skill">HTML <i class="val">+10 Anos</i></span>
              <div class="progress-bar-wrap">
                <div class="progress-bar" role="progressbar" aria-valuenow="10" aria-valuemin="0" aria-valuemax="10" aria-label="HTML"></div>
              </div>
            </div>
            <div class="progress">
              <span class="skill">CSS <i class="val">+10 Anos</i></span>
              <div class="progress-bar-wrap">
                <div class="progress-bar" role="progressbar" aria-valuenow="10" aria-valuemin="0" aria-valuemax="10" aria-label="CSS"></div>
              </div>
            </div>
            <div class="progress">
              <span class="skill">SCSS <i class="val">5 Anos</i></span>
              <div class="progress-bar-wrap">
                <div class="progress-bar" role="progressbar" aria-valuenow="5" aria-valuemin="0" aria-valuemax="10" aria-label="SCSS"></div>
              </div>
            </div>

            

          </div>

          <div class="col-lg-6">

            <div class="progress">
              <span class="skill">Javascript <i class="val">5 Anos</i></span>
              <div class="progress-bar-wrap">
                <div class="progress-bar " role="progressbar" aria-valuenow="5" aria-valuemin="0" aria-valuemax="10" aria-label="Javascript"></div>
              </div>
            </div>
            <div class="progress">
              <span class="skill">Ajax <i class="val">+10 Anos</i></span>
              <div class="progress-bar-wrap">
                <div class="progress-bar " role="progressbar" aria-valuenow="10" aria-valuemin="0" aria-valuemax="10" aria-label="Ajax"></div>
              </div>
            </div>
            <div class="progress">
              <span class="skill">API <i class="val">6 Anos</i></span>
              <div class="progress-bar-wrap">
                <div class="progress-bar " role="progressbar" aria-valuenow="6" aria-valuemin="0" aria-valuemax="10" aria-label="API"></div>
              </div>
            </div>
            <div class="progress">
              <span class="skill">C++ <i class="val">5 Anos</i></span>
              <div class="progress-bar-wrap">
                <div class="progress-bar" role="progressbar" aria-valuenow="5" aria-valuemin="0" aria-valuemax="10" aria-label="C++"></div>
              </div>
            </div>
            <div class="progress">
              <span class="skill">Arduino <i class="val">6 Anos</i></span>
              <div class="progress-bar-wrap">
                <div class="progress-bar " role="progressbar" aria-valuenow="6" aria-valuemin="0" aria-valuemax="10" aria-label="Arduino"></div>
              </div>
            </div>
            <div class="progress">
              <span class="skill">Jquery <i class="val">4 Anos</i></span>
              <div class="progress-bar-wrap">
                <div class="progress-bar " role="progressbar" aria-valuenow="4" aria-valuemin="0" aria-valuemax="10" aria-label="Jquery"></div>
              </div>
            </div>
            <div class="progress">
              <span class="skill">MQL5 <i class="val">3 Anos</i></span>
              <div class="progress-bar-wrap">
                <div class="progress-bar " role="progressbar" aria-valuenow="3" aria-valuemin="0" aria-valuemax="10" aria-label="MQL5"></div>
              </div>
            </div>
            <div class="progress">
              <span class="skill">MVC <i class="val">+10 Anos</i></span>
              <div class="progress-bar-wrap">
                <div class="progress-bar " role="progressbar" aria-valuenow="10" aria-valuemin="0" aria-valuemax="10" aria-label="MVC"></div>
              </div>
            </div>
            <div class="progress">
              <span class="skill">SCRUM <i class="val">6 Anos</i></span>
              <div class="progress-bar-wrap">
                <div class="progress-bar " role="progressbar" aria-valuenow="6" aria-valuemin="0" aria-valuemax="10" aria-label="SCRUM"></div>
              </div>
            </div>
            <div class="progress">
              <span class="skill">RestFULL <i class="val">6 Anos</i></span>
              <div class="progress-bar-wrap">
                <div class="progress-bar " role="progressbar" aria-valuenow="6" aria-valuemin="0" aria-valuemax="10" aria-label="RestFull"></div>
              </div>
            </div>
            <div class="progress">
              <span class="skill">ERP <i class="val">8 Anos</i></span>
              <div class="progress-bar-wrap">
                <div class="progress-bar " role="progressbar" aria-valuenow="8" aria-valuemin="0" aria-valuemax="10" aria-label="ERP"></div>
              </div>
            </div>
            <div class="progress">
              <span class="skill">VUE.js <i class="val">2 Anos</i></span>
              <div class="progress-bar-wrap">
                <div class="progress-bar " role="progressbar" aria-valuenow="2" aria-valuemin="0" aria-valuemax="10" aria-label="Vue.js"></div>
              </div>
            </div>

          </div>

          

        </div>

      </div>
      
      

    </section><!-- End Skills Section -->
</template>''

<script>
  
  export default{
      name: 'SkillVue',
      
  }
</script>