import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'


import "../public/assets/vendor/aos/aos.css"
import "../public/assets/vendor/bootstrap/css/bootstrap.min.css"
import "../public/assets/vendor/bootstrap-icons/bootstrap-icons.css"
import "../public/assets/vendor/boxicons/css/boxicons.min.css"
import "../public/assets/vendor/glightbox/css/glightbox.min.css"
import "../public/assets/vendor/swiper/swiper-bundle.min.css"
import '../public/assets/style.css'

// import pure from "<%= BASE_URL %>/assets/vendor/purecounter/purecounter_vanilla"
// import aos from "<%= BASE_URL %>/assets/vendor/aos/aos"
// import bootstrap from "<%= BASE_URL %>/assets/vendor/bootstrap/js/bootstrap.bundle.min"
// import glightbox from "<%= BASE_URL %>/assets/vendor/glightbox/js/glightbox.min"
// import isotope from "<%= BASE_URL %>/assets/vendor/isotope-layout/isotope.pkgd.min"
// import swiper from "<%= BASE_URL %>/assets/vendor/swiper/swiper-bundle.min"
// import Typed from "../public/assets/vendor/typed.js/typed.min"
// import Waypoint from "../public/assets/vendor/waypoints/noframework.waypoints"
// import validate from "<%= BASE_URL %>/assets/vendor/php-email-form/validate"
// import main from "<%= BASE_URL %>/assets/js/main"


createApp(App)
    .use(store)
    .use(router)
    // .use(aos)
    // .use(bootstrap)
    // .use(pure)
    // .use(glightbox)
    // .use(isotope)
    // .use(swiper)
    // .use(Typed)
    // .use(Waypoint)
    // .use(validate)
    // .use(main)
    .mount('#app')
