<template>
    <section id="hero" class="d-flex flex-column justify-content-center">
        <div class="container" data-aos="zoom-in" >
        <h1>{{ name }}</h1>
        <p><span class="typed" ></span></p>
        <div class="social-links">
            <!-- <a href="#" class="twitter"><i class="bx bxl-twitter"></i></a>
            <a href="#" class="facebook"><i class="bx bxl-facebook"></i></a>
            <a href="#" class="instagram"><i class="bx bxl-instagram"></i></a>
            <a href="#" class="google-plus"><i class="bx bxl-skype"></i></a> -->
            <a href="https://www.linkedin.com/in/fabio-andolphi-833027137/" target="_blank" class="linkedin"><i class="bx bxl-linkedin"></i></a>
        </div>
        </div>
    </section><!-- End Hero -->
    
</template>

<script>
import { mapState } from 'vuex';

import Typed from "../../../public/assets/vendor/typed.js/typed.min"

export default{
    name: 'HomeVue',
    computed: {
        ...mapState({
            name: state => state.name,
            items: state => state.items,
        })
    },
    mounted(){
        
        if (this.items) {
            new Typed('.typed', {
            strings: this.items,
            loop: true,
            typeSpeed: 100,
            backSpeed: 50,
            backDelay: 2000
            });
        }
    }
}
</script>