<template>
    <!-- ======= Contact Section ======= -->
    <section id="contact" class="contact">
      <div class="container" data-aos="fade-up">

        <div class="section-title">
          <h2>Contato</h2>
        </div>

        <div class="row mt-1">

          <div class="col-lg-4">
            <div class="info">

              <div class="email">
                <i class="bi bi-envelope"></i>
                <h4>Email:</h4>
                <p>{{ email }}</p>
              </div>

              <div class="phone">
                <a :href="whatsapp_web" target="_blank" rel="noopener noreferrer">
                  <i class="bi bi-whatsapp"></i>
                </a>
                <h4>Whatsapp:</h4>
                <p>{{ cell }}</p>
              </div>

            </div>

          </div>

          <div class="col-lg-8 mt-5 mt-lg-0">

            

          </div>

        </div>

      </div>
    </section><!-- End Contact Section -->
</template>

<script>
import axios from 'axios'
import { mapState } from 'vuex'
    export default{
        name: 'ContactVue',
        data(){
          return {
            loading: false,
            sent_message: false,
            vbutton: true,
            isMobile: window.innerWidth < 768,
            formdata: {
              cname:'',
              cemail:'',
              csubject:'',
              cmessage:'',
            }
          }
        },
        methods:{
          submitform(){
            this.vbutton = false
            this.loading = true
            axios.post('https://fabio.andolphi.com.br/forms/contact.php', this.formdata,{
            // axios.post('http://localhost:8181/curriculo-vue/public/forms/contact.php', this.formdata,{
              headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
              }
            })
            .then(response => {
                console.log('response',response.data);
                this.formdata.cname =  ''
                this.formdata.cemail =  ''
                this.formdata.csubject =  ''
                this.formdata.cmessage =  ''
                this.loading = false
                this.sent_message = true
                setTimeout(() => {
                  this.sent_message = false
                }, 10000);
                setTimeout(() => {
                  this.vbutton = true
                }, 11000);
            })
            .catch(error => {
                console.log('response',error);
                console.log('response_error',error.response);
            });
          }
        },
        computed: {
          ...mapState({
            email: state => state.email,
            cell: state => state.whatsapp,
            address: state => state.address,
          }),
          whatsapp_web(){
            if(this.isMobile)
              return `https://api.whatsapp.com/send?phone=${this.cell}`
            else
              return `https://web.whatsapp.com/send?phone=5522997399279`
          },
        }
    }
</script>

<style>

.vbutton-enter-from{
  opacity: 0;
}

.vbutton-enter-active{
  transition: 1s;
}

.vbutton-enter-to{
  opacity: 1;
}

.load-enter-from{
  opacity: 0;
}

.load-enter-active{
  transition: 1s;
}

.load-enter-to{
  opacity: 1;
}

/* .load-leave-from{
  opacity: 1;
}

.load-leave-active{
  transition: 1s;
}

.load-leave-to{
  opacity: 0;
} */

.sent-enter-from{
  opacity: 0;
}

.sent-enter-active{
  transition: 1s;
}

.sent-enter-to{
  opacity: 1;
}

.sent-leave-from{
  opacity: 1;
}

.sent-leave-active{
  transition: 1s;
}

.sent-leave-to{
  opacity: 0;
}



</style>