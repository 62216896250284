<template>
  <!-- <nav>
    <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link>
  </nav>
  <router-view/> -->
  <div>
    <header-vue></header-vue>
    <content-vue></content-vue>
    <footer-vue></footer-vue>
  </div>
  
</template>

<script>
import FooterVue from './components/layout/FooterVue.vue'
import HeaderVue from './components/layout/HeaderVue.vue'
import ContentVue from './components/layout/ContentVue.vue';

export default{
  name:'App',
  components: {
    FooterVue,
    HeaderVue,
    ContentVue,
  },
}
</script>

