import { createStore } from 'vuex'

export default createStore({
  state: {
    name:'Fabio Andolphi',
    email: 'fabioandolphi@hotmail.com',
    whatsapp: '+55 22 99739-9279',
    linkedin: 'https://www.linkedin.com/in/fabio-andolphi-833027137/', 
    address: 'Av. João Bedim, N316 - Apto 501 Bl-1, Itaperuna-RJ, CEP: 28.300-000',
    items: [
      'Analista de Sistemas', 
      'Desenvolvedor PHP', 
      'Desenvolvedor Laravel', 
      'Desenvolvedor Arduino/ESP. ',
    ]  
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
