<template>
  <div>
    <home-vue/>
  
    <main id="main">
      <about-vue/>
      <!-- <facts-vue/> -->
      <skill-vue/>
      <resume-vue/>
      <!-- <portfolio-vue/> -->
      <!-- <services-vue/> -->
      <!-- <testimonials-vue/> -->
      <contact-vue/>
    </main><!-- End #main -->
  </div>  
</template>

<script>
import HomeVue from '@/components/content/HomeVue.vue'
import AboutVue from '@/components/content/AboutVue.vue'
import ResumeVue from '@/components/content/ResumeVue.vue'
import SkillVue from '@/components/content/SkillVue.vue'
// import FactsVue from '@/components/content/FactsVue.vue'
import ContactVue from '@/components/content/ContactVue.vue'
// import PortfolioVue from '@/components/content/PortfolioVue.vue'
// import TestimonialsVue from '@/components/content/TestimonialsVue.vue'
// import ServicesVue from '@/components/content/ServicesVue.vue'

export default{

  name:'ContentVue',
  lazy:true,
  components: {
    HomeVue,
    AboutVue,
    ResumeVue,
    SkillVue,
    // FactsVue,
    ContactVue,
    // PortfolioVue,
    // TestimonialsVue,
    // ServicesVue,
  }

}
</script>