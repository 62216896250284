<template>
    <!-- ======= Resume Section ======= -->
    <section id="resume" class="resume">
      <div class="container" data-aos="fade-up">

        <div class="section-title">
          <h2>Currículo</h2>
          <!-- <p>Trabalho com desenvolvimento de sistemas há mais de 10 anos.</p>
          <p>Possuo habilidades sólidas em PHP, Laravel, Codeigniter, Vue.js , Mysql, Postgre, SQLServer, C++ , Plataforma Arduino, Genexus e Robotica.</p>
          <p>Dedicação e comprometimento são características marcantes da minha trajetória, que inclui atuação em empresas de destaque no mercado.</p>
          <p>Busco sempre aprimorar meus conhecimentos e habilidades, e estou motivado para contribuir com o sucesso da empresa. Acredito que posso agregar valor à equipe e contribuir para o crescimento da empresa.</p> -->
        </div>

        <div class="row">
          
          <div class="col-lg-6">
            <h3 class="resume-title">Experiência Profissional</h3>
            <div class="resume-item">
              <h4>Hammer Consult</h4>
              <h5>Desenvolvedor Fullstack Pleno</h5>
              <h5>03/2023 - o momento</h5>
              <ul>
                <li>Desenvolvedor nível Pleno em uma empresa especializada em soluções de TI com mais de 260 clientes espalhados por todo Brasil e América Latina. Atendendo um de seus clientes de enorme importância no setor público do estado de Minas Gerais.</li>
              </ul>
            </div>
            <div class="resume-item">
              <h4>Ezoom</h4>
              <h5>Executivo de Contas</h5>
              <h5>12/2022 - 02/2023</h5>
              <ul>
                <li>Elo de ligação entre o cliente e o time tecnico, entendendo a expectativa do cliente fazendo a triagem e o cronograma das demandas e traduzindo de forma que o time tecnico possa executar, e atuando em todos os ambientes do projeto: financeiro, tecnico e pessoal.</li>
              </ul>
            </div>
            <div class="resume-item">
              <h4>Ezoom</h4>
              <h5>Desenvolvedor Full Stack - Tech Lead</h5>
              <h5>08/2021 - 12/2022</h5>
              <ul>
                <li>Liderar equipe multidisciplinar</li>
                <li>Desenvolver e dar manutenção em aplicações baseadas em: Codeignite, Laravel com banco de dados Mysql.</li>
              </ul>
            </div>
            <div class="resume-item">
              <h4>Microsia Consultoria E Sistemas</h4>
              <h5>Desenvolvedor</h5>
              <h5>02/2015 - 08/2022</h5>
              <ul>
                <li>Manutenção e implementeção na programação dos sistemas de gestão da empresa utilizando a ferramenta CASE GENEXUS (Interface Windows e Web).</li>
                <li>Consultoria em sistemas em ambiente WEB.</li>
                <li><em>***Minha experiência em ambiente web agregou conhecimento para a empresa nesta área.</em></li>
                
              </ul>
            </div>
            <div class="resume-item">
              <h4>Colégio Único</h4>
              <h5>Técnico de suporte em TI</h5>
              <h5>01/2014 - 02/2015</h5>
              <ul>
                <li>Desenvolver e melhorar metodologias de governança da empresa.</li>
                <li>Coordenar os processos internos e identificar possibilidades de melhorias nas atividades burocráticas.</li>
                <li>Manter o funcionamento da estrutura de T.I. da empresa.</li>
              </ul>
            </div>
            <div class="resume-item">
              <h4>CTCEA - Org. Brasileira para o Desenvolvimento Científico e Tecnológico do Controle do Espaço Aéreo </h4>
              <h5>Analista de Sistemas | Desenvolvedor </h5>
              <h5>01/2010 - 01/2014</h5>
              <ul>
                <li>Desenvolvimento de sistemas em ambiente WEB(Intranet/Extranet e Webservice)</li>
                <li>Linguagem: PHP + MSSQL/MYSQL + APACHE.</li>
                <li>Modelo MVC</li>
                <li>Levantamento de requisitos</li>
                <li>Elaboração/Validação de casos de uso, estrutura de dados, plano de teste, massa de dados, etc....</li>
                <li>Realização de SAT e FAT.</li>
                <li>Prestar apoio e suporte ao usuário final.</li>
                <li>Realizar manutenção nos sistemas.</li>
                <li><em>**Fui coordenador técnico de um projeto em parceria com a COPPETEC / UFRJ de um sistema de diagnostico e previsão de ventos com a utilização de SODAR (Tipo de sonar) para principalmente identificar e prever Wind-shear (tesoura de vento) e assim auxiliar a torre de controle na tomada de decisão.</em></li>
              </ul>
            </div>
            <div class="resume-item">
              <h4>ANDOLPHI & OLIVEIRA CONSULTORIA E PROJETOS LTDA</h4>
              <h5>Analista de Sistemas | Desenvolvedor </h5>
              <h5>12/2007 - 08/2010</h5>
              <ul>
                <li>Desenvolvimento de sistemas em ambiente WEB(Intranet/Extranet e Webservice)</li>
                <li>Linguagem: PHP + MSSQL/MYSQL + APACHE.</li>
                <li>Modelo MVC</li>
                <li>Levantamento de requisitos</li>
                <li>Elaboração/Validação de casos de uso, estrutura de dados, plano de teste, massa de dados, etc....</li>
                <li>Realização de SAT e FAT.</li>
                <li>Prestar apoio e suporte ao usuário final.</li>
                <li>Realizar manutenção nos sistemas.</li>
                <li><em>**Fui coordenador técnico de um projeto em parceria com a COPPETEC / UFRJ de um sistema de diagnostico e previsão de ventos com a utilização de SODAR (Tipo de sonar) para principalmente identificar e prever Wind-shear (tesoura de vento) e assim auxiliar a torre de controle na tomada de decisão.</em></li>
              </ul>
            </div>
            <div class="resume-item">
              <h4>HOTEL GLÓRIA</h4>
              <h5>Gerente de TI</h5>
              <h5>12/2004 - 12/2007</h5>
              <ul>
                <li>Gerenciamento de toda infra estrutura de informática, redes, computadores e servidores.</li>
                <li>Manutenção do sistema Hoteleiro e de telecomunicações.</li>
                <li>Implantação e implementação de todas as soluções em TI da empresa.</li>
                <li>Desenvolvimento de ferramentas que melhorem a parte operacional da empresa.</li>
                <li>Suporte de informática aos colaboradores e hóspedes.</li>
              </ul>
            </div>
            
          </div>

          <div class="col-lg-6">
            <!-- <h3 class="resume-title">Sumary</h3>
            <div class="resume-item pb-0">
              <h4>Brandon Johnson</h4>
              <p><em>Innovative and deadline-driven Graphic Designer with 3+ years of experience designing and developing user-centered digital/print marketing material from initial concept to final, polished deliverable.</em></p>
              <ul>
                <li>Portland par 127,Orlando, FL</li>
                <li>(123) 456-7891</li>
                <li>alice.barkley@example.com</li>
              </ul>
            </div> -->

            <h3 class="resume-title">Formação</h3>
            <div class="resume-item">
              <h4>Bacharelado em Ciência da Informação e Comunicação.</h4>
              <h5>conclusão 2004</h5>
              <p><em>Universidade Santa Úrsula, Botafogo - RJ</em></p>
            </div>
            <!-- <div class="resume-item">
              <h4>Bachelor of Fine Arts &amp; Graphic Design</h4>
              <h5>2010 - 2014</h5>
              <p><em>Rochester Institute of Technology, Rochester, NY</em></p>
              <p>Quia nobis sequi est occaecati aut. Repudiandae et iusto quae reiciendis et quis Eius vel ratione eius unde vitae rerum voluptates asperiores voluptatem Earum molestiae consequatur neque etlon sader mart dila</p>
            </div> -->
          </div>
        </div>

      </div>
    </section><!-- End Resume Section -->
</template>

<script>
export default{
    name: 'ResumeVue',
}
</script>